/* 
.ant-input   {
        margin-left: -9%;
        width: 500px;
        height: 30px;
    }
.ant-form-item-control-input-content{
    display: flex;
    justify-content: space-evenly;
}           
.ant-form-item-required{
    margin-left: -4%;
} 
div{
    
            max-width:100% !important;
    
       }        
.ref{
    margin-left: -9%;
    width: 555px;
    height: 81px;
}
.button-type {
    margin-left: 48%;
    margin-top: 1%;
}     
          
    
.submit {
    
    font-size: medium;
    max-width: 280px;
}
.chartered{
    margin-top: 3%;
   
    
   
    
    
   
}   
.form_p{
    
    border: 0 none;
    border-radius: 3px;
  text-align: center;
}   
.reference{
    margin-top: 3%;
}
 .form{
     margin-left: 44%;
 }    
.form_div{
    background: white;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 0 15px 1px rgb(0 0 0 / 40%);
    padding: 20px 30px;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    min-height: 495px;
    position: relative;
}   
       
     
@media only screen and (min-width:360px) and (max-width:740px){
    .form {
        
        margin-left: 27%;
    }
    .form_p {
    border: 0 none;
    border-radius: 3px;
    text-align: center;
    width: 100%;
}
.ant-form-item-control-input-content {
    display: block;
    justify-content: space-evenly;
}
.ant-input {
    margin-left: 8%;
    width: 250px;
    height: 30px;
}
.ant-form-item-required {
    margin-left: 9%;
}
.button-type {
    margin-left: 32%;
    margin-top: 3%;
}
}
@media only screen and (min-width:768px) and (max-width:1024px){

    .ant-form-item-control-input-content {
        display: grid;
        justify-content: space-evenly;
    }
    .ant-input {
        margin-left: 2%;
        width: 500px;
        height: 30px;
    }
    .ant-form-item-required {
        margin-left: 3%;
    }
    .button-type {
        margin-left: 44%;
        margin-top: 1%;
    }
    .form {
        margin-left: 42%;
    }
} */
.col-8 {
        margin-left: 0%; 
        display: flex;
        justify-content: space-evenly;
        padding: 15px;
    

}
.control{
    width: 78%;
    height: 50%;
   
}
.button {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    background: grey;
    width: 12%;
    margin-top: -5%;
}

.company {
    margin-left: 0%; 
    display: flex;
    padding: 15px;
    


}
.col-8 pt-3 mx-auto{
    margin-left: 0%;
    display: flex;
    justify-content: space-evenly;
    padding: 15px;
    margin-top: -5%;
}

.div{
    display: grid;
}
.reference {
    margin-left: 22.3%;
    padding: 15px;
}
.row pt-5 mx-auto{
    margin-top: 2%;
}
.form-control {
    width: 250px;
    margin-left: 0%;
    padding: 5px;
}
.form_p {
    border: 0 none;
    border-radius: 3px;
    text-align: center;
    width: 70%;
    margin-left: 17%;
    margin-top: -2%;
} 
.form {
    margin-left: 44%;
    margin: 3%;
    text-align: center;
    margin-top: 7%;
} 
.career_com{
    display:grid;
    margin-left:22.3%;
}
.career{
    display:grid;
    margin-left:22%;
}
.month{
    display:grid;
    margin-left:5%;
    margin-top:2%;
}

@media only screen and (min-width:360px) and (max-width:740px){
    .col-8 {
        margin-left: 0%;
        display: grid;
        justify-content: space-evenly;
        padding: 15px;
    }
    .company {
        margin-left: 0%;
        display: grid;
        padding: 15px;
    }
    .career_com {
        display: grid;
        margin-left: 17.3%;
    }
    .career {
        display: grid;
        margin-left: 18%;
        margin-top: 2%;
    }    
    .month {
        display: grid;
        margin-left: 18%;
        margin-top: 2%;
        padding: -2px;
    }
    .reference {
        margin-left: 16.3%;
        padding: 15px;
    }
    .sel_month{
        width: 80%;
        height: 148%;
    }
    .form {
        margin-left: 44%;
        margin: 3%;
            margin-top: 3%;
        text-align: center;
        margin-top: 30%;
    }
    .button {
        padding: 8px 20px;
        border-radius: 2px;
        outline: none;
        border: none;
        cursor: pointer;
        background: grey;
        width: 108%;
        margin-top: -33%;
        margin-left: -8%;
    }
    .form_p {
        border: 0;
        border-radius: 3px;
        width: 96%;
        margin-left: 1%;
        margin-top: -2%;
    }
}
@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
    .company {
        margin-left: 17%;
        display: grid;
        padding: 15px;
    }
    .col-8 {
        margin-left: 0%;
        display: flex;
        justify-content: space-evenly;
        padding: 15px;
    }
    .button {
        padding: 8px 20px;
        border-radius: 0px;
        outline: none;
        border: none;
        cursor: pointer;
        background: grey;
        width: 30%;
        margin-top: -13%;
        margin-left: 1%;
    }
    .form {
        margin-left: 44%;
        margin: 3%;
            margin-top: 3%;
        text-align: center;
        margin-top: 10%;
    }
}
@media only screen and (min-width:768px) and (max-width:1024px) {
.button {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    background: grey;
    width: 20%;
}}