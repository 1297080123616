.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}
.top.scll-top {
  opacity: 1;
  transition: ease-in 0.5s;
  bottom: 50px;
}
.designed{
  color: white;
  margin-left: -10%;
}
.top {
  position: fixed;
  right: 10px;
  bottom: 30px;
  text-align: center;
  color: #fff;
  background: #f9bf1e;
  padding: 10px 10px;
  z-index: 9;
  border-radius: 50%;
  border: 2px solid #d3a722;
  cursor: pointer;
  opacity: 0;
  transition: ease-in 0.5s;
}
.top .fa {
  display: block !important;
}
.fa-arrow-up:before {
  content: "\f062";
}
.top {
  position: fixed;
  right: 10px;
  bottom: 30px;
  text-align: center;
  color: #fff;
  background: #f9bf1e;
  padding: 10px 10px;
  z-index: 9;
  border-radius: 50%;
  border: 2px solid #d3a722;
  cursor: pointer;
  opacity: 0;
  transition: ease-in 0.5s;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-fade-enter, .ant-fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.footer{
  
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
   
}
 h4::before, footer .footer-top h4::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
}
.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}
.usefullink{
  margin-left: -58%;
  color: white;
}

  .quicklinks {
    margin-left: -13%;
    margin-top: -9%;
    color: white;
}
.footer_address {
  color: #fff;
  margin-left: 65%;
  margin-top: -10%;
}
.links{
  color: white;
}
.color{
  color: orange;
}
.footer_p{
  width: 50%;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: -97px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}


@media only screen and (min-width:360px) and (max-width:740px){
  .usefullink {
    margin-left: 9%;
    color: white;
}
.quicklinks {
  margin-left: -29%;
  margin-top: 7%;
  color: white;
}
.footer_address {
  color: white;
  margin-left: 17%;
  margin-top: 5%;
}
.footer_p {
  width: 81%;
}
.footer-logo{
  margin-left: 27%;
} 
.designed {
  color: white;
  margin-left: 2%;
  margin-top: 2%;
}
}
@media only screen and (min-width:768px) and (max-width:1024px){
  .usefullink {
    margin-left: -38%;
  }
  .quicklinks {
    margin-left: 22%;
    margin-top: -18%;
    color: white;
}
.footer_address {
  color: white;
  margin-left: 34%;
  margin-top: 6%;
}
.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 9px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.designed {
  color: white;
  margin-left: 1%;
  margin-top: 2%;
}
}
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape) {
  .google {
    margin-top: 5%;
    margin-left: 22%;
}
.quicklinks {
  margin-left: 22%;
  margin-top: -13.5%;
  color: white;
}
}
@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
  .usefullink {
    margin-left: -39%;
    color: white;
}
.quicklinks {
  margin-left: 31%;
  margin-top: -21%;
  color: white;
}
.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: -162px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
}