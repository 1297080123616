.manage{
    margin-left: 5%;
    font-size: x-large;
    margin-top: 2%;
    font-family: -webkit-body;
    color: white;
}
.mancolor{
    color:white;
    text-align: center;
}
.management {
    background: #000;
    margin-top: 6.5%;
    opacity: .85;
    height: 70vh;
}


@media only screen and (min-width:360px) and (max-width:740px){
    .manage {
        margin-left: 5%;
        font-size: medium;
        margin-top: 2%;
        font-family: -webkit-body;
        line-height: 1.5em;
    }
   
    .management {
        background: black;
        margin-top: 27.5%;
        opacity: 0.85;
        height: 70vh;
    }
}

@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
.management {
    background: black;
    margin-top: 9.5%;
    opacity: 0.85;
    height: 99vh;
}
}

@media only screen and (min-width:768px) and (max-width:1024px){
    .management {
        background: black;
        margin-top: 6.5%;
        opacity: 0.85;
        height: 43vh;
    }
  
}

