.google {
    margin-top: -14%;
    margin-left: 65%;
}
.googlemap {
    width: 430px;
    height: 250px;
}
.location {
    width: 6%;
    margin-left: 2%;
    margin-top: 4%;
}
.contact-h2 {
    margin-left: 9%;
    font-size: 119%;
    margin-top: -5%;
    font-family: auto;
}
.contact-h1 {
    margin-top: 0%;
    color: rgb(51, 51, 51);
    min-height: auto;
    font-family: sans-serif;
    line-height: 1.5;
    border-color: rgb(51, 51, 51);
    border-style: none;
    border-width: 0px;
    border-radius: 0px;
    letter-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 6%;
    text-align: center;
}
.contact-p {
    margin-left: 9%;
    width: 27%;
}
.message {
    width: 8%;
    margin-left: 37%;
    margin-top: -11%;
}
.contact-h21 {
    margin-left: 45%;
    font-size: 119%;
    margin-top: -6%;
    font-family: auto;
    width: 40%;
}
.contact-p1 {
    margin-left: 45%;
}
.contact-table2 {
    margin-left: 37%;
    margin-top: -8%;
}
.contact-table4 {
    margin-left: 56%;
    margin-top: -4%;
}
.contact-table1 {
    margin-left: -1%;
}
.contact-table3{
    margin-left: 13%;
}
.calls {
    width: 7%;
    margin-top: -4%;
    margin-left: 2%;
}
.contact-h2-call {
    margin-left: 9%;
    margin-top: -5%;
}
.contact-p-call {
    margin-left: 9%;
}
.time {
    width: 5%;
    margin-left: 38%;
    margin-top: 5%;
}
.contact-h2-time {
    margin-left: 45%;
    font-size: 119%;
    font-family: auto;
    margin-top: -4%;
}
.contact-p-time {
    margin-left: 45%;
}
@media only screen and (min-width:360px) and (max-width:740px){
    .google {
        margin-top: 5%;
        margin-left: 2%;
    }
    .googlemap {
        width: 399px;
        height: 228px;
    }
    
    .location {
        width: 17%;
        margin-left: 6%;
        margin-top: 8%;
    }
    .contact-h2 {
        margin-left: 28%;
        font-size: 119%;
        margin-top: -19%;
        font-family: auto;
    }
    .contact-p {
        margin-left: 29%;
        width: 60%;
    }
    .message {
        width: 16%;
        margin-left: 7%;
        margin-top: 5%;
    }
    .contact-h21 {
        margin-left: 30%;
        font-size: 119%;
        margin-top: -14.35%;
        font-family: auto;
        width: 40%;
    }
    .contact-p1 {
        margin-left: 30%;
    }
    .calls {
        width: 15%;
        margin-top: 12%;
        margin-left: 9%;
    }
    .contact-h2-call {
        margin-left: 30%;
        margin-top: -15%;
        font-size: 119%;
        font-family: auto;
        width: 100%;
    }
    .contact-p-call {
        margin-left: 30%;
        width: 100%;
    }
    .time {
        width: 14%;
        margin-left: 11%;
        margin-top: 10%;
    }
    .contact-h2-time {
        margin-left: 31%;
        font-size: 119%;
        font-family: auto;
        margin-top: -13%;
    }
    .contact-p-time {
        margin-left: 31%;
    }
    .contact-h1 {
        margin-top: 33%;
        color: rgb(51, 51, 51);
        min-height: auto;
        font-family: "PT Serif";
        line-height: 1.5;
        border-color: rgb(51, 51, 51);
        border-style: none;
        border-width: 0px;
        border-radius: 0px;
        letter-spacing: 0px;
        background-color: rgba(0, 0, 0, 0);
    }
}
@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
    .location {
        width: 9%;
        margin-left: 9%;
        margin-top: 5%;
    }
    .contact-h1 {
        margin-top: 10%;
        color: rgb(51, 51, 51);
        min-height: auto;
        font-family: "PT Serif";
        line-height: 1.5;
        border-color: rgb(51, 51, 51);
        border-style: none;
        border-width: 0px;
        border-radius: 0px;
        letter-spacing: 0px;
        background-color: rgba(0, 0, 0, 0);
    }
    .contact-h2 {
        margin-left: 23%;
        font-size: 119%;
        margin-top: -9%;
        font-family: auto;
    }
    .contact-p {
        margin-left: 23%;
        width: 68%;
    }
    .message {
        width: 8%;
        margin-left: 10%;
        margin-top: 6%;
    }
    .contact-h21 {
        margin-left: 24%;
        font-size: 119%;
        margin-top: -7.35%;
        font-family: auto;
        width: 40%;
    }
    .contact-p1 {
        margin-left: 24%;
    }
    .calls {
        width: 6%;
        margin-top: 8%;
        margin-left: 11%;
    }
    .contact-h2-call {
        margin-left: 24%;
        margin-top: -7%;
        font-size: 119%;
        font-family: auto;
        width: 100%;
    }
    .contact-p-call {
        margin-left: 24%;
        width: 100%;
    }
    .time {
        width: 7%;
        margin-left: 12%;
        margin-top: 4%;
    }
    .contact-h2-time {
        margin-left: 24%;
        font-size: 119%;
        font-family: auto;
        margin-top: -7%;
    }
    .contact-p-time {
        margin-left: 24%;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px){
    .location {
        width: 9%;
        margin-left: 9%;
        margin-top: 4%;
    }
    .contact-h2 {
        margin-left: 22%;
        font-size: 119%;
        margin-top: -9%;
        font-family: auto;
    }
    .contact-p {
        margin-left: 22%;
        width: 100%;
    }
    .message {
        width: 9%;
        margin-left: 9%;
        margin-top: 5%;
    }
    .contact-h21 {
        margin-left: 22%;
        font-size: 119%;
        margin-top: -8.35%;
        font-family: auto;
        width: 40%;
    }
    .contact-p1 {
        margin-left: 22%;
    }
    .calls {
        width: 8%;
        margin-top: 11%;
        margin-left: 10%;
    }
    .contact-h2-call {
        margin-left: 22%;
        margin-top: -8%;
        font-size: 119%;
        font-family: auto;
        width: 100%;
    }
    .contact-p-call {
        margin-left: 22%;
        width: 100%;
    }
    .time {
        width: 8%;
        margin-left: 11%;
        margin-top: 5%;
    }
    .contact-h2-time {
        margin-left: 22%;
        font-size: 119%;
        font-family: auto;
        margin-top: -8%;
    }
    .contact-p-time {
        margin-left: 22%;
    }
    .google {
        margin-top: 5%;
        margin-left: 9%;
    }
}
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape) {
    .contact-h1 {
       margin-top: 5%;
        color: rgb(51, 51, 51);
        min-height: auto;
        font-family: "PT Serif";
        line-height: 1.5;
        border-color: rgb(51, 51, 51);
        border-style: none;
        border-width: 0px;
        border-radius: 0px;
        letter-spacing: 0px;
        background-color: rgba(0, 0, 0, 0);
    }
    .contact-table1 {
        margin-left: -11%;
    }
    .contact-table2 {
        margin-left: 19%;
        margin-top: -13%;
    }
    .calls {
        width: 8%;
        margin-top: 9%;
    }
    .contact-table3 {
        margin-left: 11%;
    }
    .contact-table4 {
        margin-left: 47%;
    }    
}

