.testi{
    background:lightgrey;
    margin-top:6.5%;
}


@media only screen and (min-width:360px) and (max-width:740px){
.testi {
    background: lightgrey;
    margin-top: 27.5%;
    height: 110vh;
}
}

@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
    .testi {
        background: lightgrey;
        margin-top: 9.5%;
        height: 145vh;
    }
}