.header {
  
    background: url('./image/background.jpg') center center/cover no-repeat; 
     height: 80vh;
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
     object-fit: contain;
   }
   .button {
    background: lightgray;
    border: none;
    color: white;
    font-size: 124%;
    width: 10%;
    /* border-start-start-radius: 20px; */
    /* border-end-end-radius: 20px; */
    /* border-end-start-radius: 20px; */
    /* border-start-end-radius: 20px; */
    padding: 5px;
    border-radius: 20px;
    margin-top: 2%;
}

.carousel_title p {
  text-align: center;
  font-family: Audiowide;
  margin-bottom: 0;
  font-size: 50px;
  font-weight: bolder;
  color:blue;
}
.carousel_title1 p {
  text-align: center;
  font-family: Audiowide;
  margin-bottom: 0;
  font-size: 50px;
  font-weight: bolder;
  color:orange;
}
.carousel_title2 p {
  display: none;
  text-align: center;
  font-family: Audiowide;
  margin-bottom: 0;
  font-size: 30px;
  font-weight: bolder;
  color:blue;
  text-decoration: underline;
  background:white;
}
.title {
  left: 1px;
  position: fixed;
  top: 50px;
  
}
.title1 {
  left:170px;
  position: fixed;
  top: 50px;
  z-index: 9;
  
}
.title2 p {
  left: 0px;
  position: fixed;
  top: 450px;
  z-index: 9;
  font-size: 55%;
}


.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  left: 30px;
}

.whatsapp-icon {
    margin-top: 16px;
}
.header-whatsapp{
  width: 100%;
  /* margin-top: -100%;
  margin-left: 5%; */
}

.header-h1 {
  color: white;
  font-family: sans-serif;
  font-size: 2em;
}
@media only screen and (min-width:360px) and (max-width:740px){
   .button {
     background: lightgray;
     border: none;
     color: white;
     font-size: 100%;
     width: 34%;
     border-start-start-radius: 20px;
     border-end-end-radius: 20px;
     border-end-start-radius: 20px;
     border-start-end-radius: 20px;
   }
  
   .header {
    background: url(/static/media/background.c6509552.jpg) center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}
}
   @media only screen and (min-width:768px) and (max-width:1024px){
     .button {
       background: lightgray;
       border: none;
       color: white;
       font-size: 100%;
       width: 20%;
       border-radius: 20px;
       
   }
   .header {
    background: url(/static/media/background.c6509552.jpg) center center/cover no-repeat;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}
   }
   @media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
    .button {
      background: lightgray;
      border: none;
      color: white;
      font-size: 124%;
      width: 19%;
      border-start-start-radius: 20px;
      border-end-end-radius: 20px;
      border-end-start-radius: 20px;
      border-start-end-radius: 20px;
      padding: 5px;
      border-radius: 20px;
      margin-top: 2%;
  }
   }
   @media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape){
    .header {
      background: url(/static/media/background.c6509552.jpg) center center/cover no-repeat;
      height: 75vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
      object-fit: contain;
  }
   }