.gall_img {
    width: 27%;
    border-end-end-radius: 12px;
    margin-left: 5%;
}
.gall_img1 {
    width: 27%;
    border-end-end-radius: 12px;
    margin-left: 5%;
}
.gall_img2 {
    width: 25%;
    border-end-end-radius: 12px;
    height: 274px;
    margin-left: 5%;
}
.gallery-h1 {
    color: rgb(51, 51, 51);
    min-height: auto;
    font-family: "PT Serif";
    line-height: 1.5;
    border-color: rgb(51, 51, 51);
    border-style: none;
    border-width: 0px;
    border-radius: 0px;
    letter-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 6%;
}
@media(max-width:420px){
.gall_img {
    width: 92%;
    border-end-end-radius: 12px;
    margin-left: 5%;
}
.gall_img1 {
    width: 92%;
    border-end-end-radius: 12px;
    margin-left: 5%;
    margin-top: 5%;
}
.gall_img2 {
    width: 92%;
    border-end-end-radius: 12px;
    height: 274px;
    margin-left: 5%;
    margin-top: 5%;
}
}
@media only screen and (min-width:768px) and (max-width:1024px){
    .gall_img2 {
        width: 25%;
        border-end-end-radius: 12px;
        height: 141px;
        margin-left: 5%;
    }
}
@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
    .gallery-h1 {
        color: rgb(51, 51, 51);
        min-height: auto;
        font-family: "PT Serif";
        line-height: 1.5;
        border-color: rgb(51, 51, 51);
        border-style: none;
        border-width: 0px;
        border-radius: 0px;
        letter-spacing: 0px;
        background-color: rgba(0, 0, 0, 0);
        margin-top: 5%;
    }
    .gall_img2 {
        width: 25%;
        border-end-end-radius: 12px;
        height: 135px;
        margin-left: 5%;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape) {

    .gall_img2 {
        width: 25%;
        border-end-end-radius: 12px;
        height: 141px;
        margin-left: 5%;
        height: 186px;
    }
}