.whyus_h1{
    margin-top: 7%;
    font-size: xx-large;
    color: black;
    min-height: auto;
    font-family: sans-serif;
    line-height: 1.5;
    border-color: rgb(51, 51, 51);
    border-style: none;
    border-width: 0px;
    border-radius: 0px;
    letter-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    text-align: center;
    
    
}
.three{
    display:flex;
    justify-content:center;
    margin-top:4%;
}
.why_image {
    width: 50%;
    margin-left: 24%;
    margin-top: 8%;
}
.why_image1 {
    width: 67%;
    margin-left: 16%;
    margin-top: 1%;
}
.why_image2 {
    width: 58%;
    margin-left: 21%;
    margin-top: 2%;
}
.table_h1 {
    font-size: x-large;
    text-align: center;
}
.table_h2 {
    margin-top: -4%;
    font-size: x-large;
    /* margin-left: 11%; */
    text-align: center;
}
.table_h3 {
    margin-top: 3%;
    font-size: x-large;
    text-align: center;
}
.square_why{
    height: 225px;
    width: 250px;
    background-color: white;
}
.class_why{
    display: flex;
    justify-content: space-evenly;
}
@media only screen and (min-width:360px) and (max-width:740px){
    
    .whyus_h1 {
        margin-top: 27%;
        font-size: xx-large;
        color: black;
        min-height: auto;
        font-family: sans-serif;
        line-height: 1.5;
        border-color: rgb(51, 51, 51);
        border-style: none;
        border-width: 0px;
        border-radius: 0px;
        letter-spacing: 0px;
        background-color: rgba(0, 0, 0, 0);
    }
    .class_why {
        display: grid;
        justify-content: space-evenly;
    }
}

@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
.whyus_h1 {
    margin-top: 12%;
    font-size: xx-large;
    color: black;
    min-height: auto;
    font-family: sans-serif;
    line-height: 1.5;
    border-color: rgb(51, 51, 51);
    border-style: none;
    border-width: 0px;
    border-radius: 0px;
    letter-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
}
.class_why {
    display: flex;
    justify-content: space-evenly;
}
}

@media only screen and (min-width:768px) and (max-width:1024px){
    
}

@media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape) {
    .whyus_h1 {
        margin-top: 11%;
        font-size: xx-large;
        color: black;
        min-height: auto;
        font-family: sans-serif;
        line-height: 1.5;
        border-color: rgb(51, 51, 51);
        border-style: none;
        border-width: 0px;
        border-radius: 0px;
        letter-spacing: 0px;
        background-color: rgba(0, 0, 0, 0);
    }
}