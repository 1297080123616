.navbar {
  height: 0px;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: relative;
  top: 0;
  z-index: 999;
}
.nav-jyo {
  color: black;
  font-size: smaller;
}
.tax
  {
    text-decoration: none;
    display: inline-block;
color: white;
}
.tax:hover{
  color: black;
}
.btn {
  
  outline: none;
  border: none;
  cursor: pointer;
  background: transparent; 
}
.btn {
  color: white;
  background: none;
  font-family: 'PT Sans';
  font-size: inherit;
  margin-top: 29%;
  margin-left: 1%;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: white;
  border: inherit;
  white-space: nowrap;
  background-color: black;
  border: 0;
  opacity: 1;
}
.dropdown-item:hover{
  background-color: orange;
  color: black;
}
.dropdown-menu.show {
  display: block;
  text-align: initial;
}
.nav-logo {
  width: 5%;
  margin-left: -12%;
  margin-top: 0%;
  border-radius: 10px;
}
.fa, .fas {
  font-weight: 900;
  color: orange;
}
.navbar-container {
  
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background: gray;
  max-width: 100%;
  margin-left: 0%;
  opacity: 0.75;
}
.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.navimg {
  width: 11%;
  margin-left: -1%;
}
.nav-img{
  width: 8%;
}



.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(9, auto);
  grid-gap: 0px;
  list-style: none;
  text-align: center;
  width: 81vw;
  justify-content: end;
  margin-right: 0rem;
}

.nav-item {
  height: 80px;
}
.top-nav-lft ul li .fa {
  font-size: 20px;
  margin-right: 6px;
  color: #f9bf1e;
}
.fa-phone:before {
  content: "\f095";
}
.ca {
  color: white;
  font-size: large;
  margin-left: 1%;
  margin-top: 0%;
  width: 20%;
  font-family: "Open Sans", sans-serif;
}
.nav_list {
  color: white;
  margin-top: -22%;
  width: 115%;
  margin-left: 48%;
}
.nav_lists {
  color: white;
  width: 150%;
  margin-left: 136%;
  margin-top: -13%;
}
.nav-links {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  /* padding: 1.5rem 0rem; */
  height: 100%;
  padding: 12px;
  font-family: 'PT Sans';
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color:black;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media only screen and (min-width:360px) and (max-width:740px){
  .NavbarItems {
    position: relative;
  }
  .nav-logo {
    width: 17%;
    margin-left: -6%;
    margin-top: -5%;
}
.navimg {
  width: 50%;
  margin-left: 0%;
  margin-top: -2%;
}
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
}

  .nav-menu.active {
    background: grey;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 0rem;
    width: 100%;
    display: table;
    color: white;
    padding: 1rem;
}

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }
  .nav_lists {
    display: none;
  }
  .nav_list {
    display: none;
  }
 
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }
  .ca {
    color: white;
    font-size: large;
    font-family: "Open Sans", sans-serif;
    margin-left: 2%;
    margin-top: -5%;
    width: 68%;
}
.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  background: grey;
  margin-top: 2%;
}
.navbar-container {
  
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background: gray;
  max-width: 100%;
  margin-left: 0%;
  opacity: 1;
}

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
@media only screen and (min-width:768px) and (max-width:1024px){
  .NavbarItems {
    position: relative;
  }
  .nav-logo {
    width: 11%;
    margin-left: -47%;
    margin-top: -1%;
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background: gray;
  max-width: 100%;
  margin-left: 0%;
  opacity: 1;
}
.btn {
  color: white;
  background: none;
  font-family: 'PT Sans';
  font-size: inherit;
  margin-top: 1%;
}
.ca {
  color: white;
  font-size: x-large;
  font-family: "Open Sans", sans-serif;
  margin-left: 2%;
  margin-top: 0%;
  width: 35%;
}
.navimg {
  width: 43%;
  margin-left: 0%;
  margin-top: -2%;
}
.nav_lists {
  display: none;
}
.nav_list {
  display: none;
}
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
}

  .nav-menu.active {
    background: grey;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 0rem;
    width: 100%;
    display: table;
    color: white;
    padding: 1rem;
}

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }

  

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }




}
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape) {
  .NavbarItems {
    position: relative;
  }
  .nav-logo {
    width: 9%;
    margin-left: -52%;
    margin-top: -1%;
}
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
}

  .nav-menu.active {
    background: grey;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 0rem;
    width: 100%;
    display: table;
    color: white;
    padding: 1rem;
}

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }

  

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}



@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
  .nav-jyo {
    color: black;
    font-size: smaller;
    margin-top: -10%;
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background: gray;
  max-width: 100%;
  margin-left: 0%;
  opacity: 1;
}
.btn {
  color: white;
  background: none;
  font-family: 'PT Sans';
  font-size: inherit;
  margin-top: 0%;
}
.ca {
  color: white;
  font-size: large;
  font-family: "Open Sans", sans-serif;
  margin-left: 2%;
  margin-top: -1%;
  width: 33%;
}
.NavbarItems {
  position: relative;
}
.nav-logo {
    width: 11%;
    margin-left: -51%;
    margin-top: 0%;
}
.navimg {
  width: 41%;
  margin-left: 0%;
  margin-top: -2%;
}
.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 110vh;
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
}

.nav-menu.active {
  background:grey;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
}

.nav-links {
  text-align: center;
  padding: 1rem;
  width: 100%;
  display: table;
  color: white;
}

.nav-links:hover {
  background-color: #fff;
  color: #242424;
  border-radius: 0;
}

.navbar-logo {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(25%, 50%);
}

.menu-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
}

.fa-times {
  color: black;
  font-size: 2rem;
}



.nav-links-mobile:hover {
  background: #fff;
  color: #242424;
  transition: 250ms;
}
}




