.tabservice{
    
    background: url('./image/services.jpg') center center/cover no-repeat; 
     height: 105vh;
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
     object-fit: contain;
     background-color: #000;
     opacity: 0.8;
}

.our_srv{
    color: black;
    min-height: auto;
    font-family: sans-serif;
    line-height: 1.5;
    border-color: rgb(51, 51, 51);
    border-style: none;
    border-width: 0px;
    border-radius: 0px;
    letter-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    
    background-color: rgba(0, 0, 0, 0);

}
.tabs {
    
    border-color: #aaa;
    color: black;
    border-radius: 5px 0px 0 0;
    margin-left: 25%;
}
.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}
.ser-sec .nav-tabs li .fa {
    margin-right: 10px;
}
.fa-hand-o-right:before {
    content: "\f0a4";
}


@media only screen and (min-width:360px) and (max-width:740px){
.our_srv {
    color: black;
    min-height: auto;
    font-family: sans-serif;
    line-height: 1.5;
    border-color: rgb(51, 51, 51);
    border-style: none;
    border-width: 0px;
    border-radius: 0px;
    letter-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    margin-top: 10%;
}
.tabservice {
    background: url(/static/media/services.213eee87.jpg) center center/cover no-repeat;
        background-color: rgba(0, 0, 0, 0);
    height: 111vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background-color: #000;
    opacity: 0.8;
}
.tabs {
    border-color: #aaa;
    color: black;
    border-radius: 5px 0px 0 0;
    margin-left: 25%;
    display: contents;
}
}

@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
    .our_srv {
        color: black;
        min-height: auto;
        font-family: sans-serif;
        line-height: 1.5;
        border-color: rgb(51, 51, 51);
        border-style: none;
        border-width: 0px;
        border-radius: 0px;
        letter-spacing: 0px;
        background-color: rgba(0, 0, 0, 0);
        background-color: rgba(0, 0, 0, 0);
        margin-top: 0%;
    }
.tabservice {
    background: url(/static/media/services.213eee87.jpg) center center/cover no-repeat;
        background-color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    height: 150vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background-color: #000;
    opacity: 0.8;
}
}


@media only screen and (min-width:768px) and (max-width:1024px){
.tabs {
    border-color: #aaa;
    color: black;
    border-radius: 5px 0px 0 0;
    margin-left: 2%;
}
.tabservice {
    background: url(/static/media/services.213eee87.jpg) center center/cover no-repeat;
        background-color: rgba(0, 0, 0, 0);
    height: 59vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background-color: #000;
    opacity: 0.8;
}
}

@media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape) {
.tabservice {
    background: url(/static/media/services.213eee87.jpg) center center/cover no-repeat;
        background-color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    height: 65vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background-color: #000;
    opacity: 0.8;
}
.tabs {
    border-color: #aaa;
    color: black;
    border-radius: 5px 0px 0 0;
    margin-left: 10%;
}
}