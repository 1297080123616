.about-img {
    width: 40%;
    height: 300px;
    margin-left: 9%;
    border-end-end-radius: 15px;
}
.zoom {
    padding: 5px;
    background-color: yellow;
    transition: transform .2s;
    width: 42%;
    height: 324px;
    margin: 0 auto;
    margin-left: 4%;
    margin-top: 3%;
}
  
  .zoom:hover {
    -ms-transform: scale(1.0); /* IE 9 */
    -webkit-transform: scale(1.0); /* Safari 3-8 */
    transform: scale(1.1); 
  }
  .about-h1 {
    color: rgb(51, 51, 51);
    min-height: auto;
    font-family: sans-serif;
    line-height: 1.5;
    border-color: rgb(51, 51, 51);
    border-style: none;
    border-width: 0px;
    border-radius: 0px;
    letter-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 6%;
    background-color: rgba(0, 0, 0, 0);
    text-align: center;

}
.about-p {
    width: 45%;
    margin-left: 52%;
    margin-top: -21%;
    text-align: justify;
    color: rgb(124, 128, 131);
    font-family: Poppins;
    line-height: 1.6;
    border-color: rgb(51, 51, 51);
    border-style: none;
    border-width: 0px;
    border-radius: 0px;
    letter-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    font-size: large;
}
.back{
    background:#f8f3f3;
    height:80vh;
}
@media only screen and (min-width:360px) and (max-width:740px){
.about-img {
    width: 84%;
    height: 300px;
    margin-left: 9%;
    border-end-end-radius: 15px;
}
.about-h1 {
    color: rgb(51, 51, 51);
    min-height: auto;
    font-family: sans-serif;
    line-height: 1.5;
    border-color: rgb(51, 51, 51);
    border-style: none;
    border-width: 0px;
    border-radius: 0px;
    letter-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 28%;
    background-color: rgba(0, 0, 0, 0);
}
.about-p {
    width: 90%;
    margin-left: 5%;
    margin-top: 6%;
    text-align: justify;
        color: rgb(124, 128, 131);
        font-family: Poppins;
        line-height: 1.6;
        border-color: rgb(51, 51, 51);
        border-style: none;
        border-width: 0px;
        border-radius: 0px;
        letter-spacing: 0px;
        background-color: rgba(0, 0, 0, 0);
        font-size: initial;
    
}
.zoom {
    padding: 5px;
    background-color: yellow;
    transition: transform .2s;
    width: 90%;
    height: 274px;
    margin: 0 auto;
        margin-top: 0px;
        margin-left: auto;
    margin-left: 5%;
    margin-top: 3%;
}
.back{
    background: rgb(248, 243, 243) none repeat scroll 0% 0%;
    height: 160vh;
}
}
@media only screen and (min-width:768px) and (max-width:1024px){
    .about-p {
        width: 94%;
        margin-left: 4%;
        margin-top: 2%;
        text-align: justify;
    }
    .about-h1 {
        color: rgb(51, 51, 51);
        min-height: auto;
        font-family: sans-serif;
        line-height: 1.5;
        border-color: rgb(51, 51, 51);
        border-style: none;
        border-width: 0px;
        border-radius: 0px;
        letter-spacing: 0px;
        background-color: rgba(0, 0, 0, 0);
        margin-top: 13%;
        background-color: rgba(0, 0, 0, 0);
    }
.about-img {
    width: 42%;
    height: 400px;
    margin-left: 5%;
    border-radius: 15px 15px 15px 15px;
    margin-top: 5%;
}
.zoom {
    padding: 5px;
    background-color: yellow;
    transition: transform .2s;
    width: 90%;
    height: 324px;
    margin: 0 auto;
        margin-top: 0px;
        margin-left: auto;
    margin-left: 5%;
    margin-top: 3%;
}
}
@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
.about-img {
    width: 42%;
    height: 400px;
    margin-left: 5%;
    border-radius: 15px 15px 15px 15px;
    margin-top: 5%;
}
.about-p {
    width: 86%;
    margin-left: 5%;
    text-align: justify;
    color: rgb(124, 128, 131);
    font-family: Poppins;
    line-height: 1.6;
    border-color: rgb(51, 51, 51);
    border-style: none;
    border-width: 0px;
    border-radius: 0px;
    letter-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 3%;
}
.zoom {
    padding: 5px;
    background-color: yellow;
    transition: transform .2s;
    width: 82%;
    height: 318px;
    margin: 0 auto;
        margin-top: 0px;
        margin-left: auto;
    margin-left: 7%;
    margin-top: 3%;
}
.back {
    background: #f8f3f3;
    height: 214vh;
}
.about-h1 {
    color: rgb(51, 51, 51);
    min-height: auto;
    font-family: sans-serif;
    line-height: 1.5;
    border-color: rgb(51, 51, 51);
    border-style: none;
    border-width: 0px;
    border-radius: 0px;
    letter-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 9%;
    background-color: rgba(0, 0, 0, 0);
    text-align: center;
}
}

@media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape) {
    .about-p {
        width: 85%;
        margin-left: 7%;
        margin-top: 1%;
        text-align: justify;
    }
    .about-img {
        width: 40%;
        height: 300px;
        margin-left: 5%;
        border-radius: 15px 15px 15px 15px;
        margin-top: 5%;
    }
    .back {
        background: #f8f3f3;
        height: 90vh;
    }
}